<template>
  <div>
    <h3 class="d-flex align-items-center mb-2">
      <feather-icon
        size="20"
        icon="ListIcon"
        class="mr-75"
      />
      <span>
        Cài đặt khác
      </span>
    </h3>
    <div>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label-for="name"
          >
            <label for="name">Thời hạn đổi mật khẩu</label>
            <b-form-input
              v-model="dataInput.passwordExpired"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </div>
    <h3 class="d-flex align-items-center mb-2">
      <feather-icon
        size="20"
        icon="AlertCircleIcon"
        class="mr-75"
      />
      <span>
        Cấu hình log
      </span>
    </h3>
    <div>
      <b-row>

        <b-col cols="4">
          <b-form-group
            label="Cấp độ log"
            label-for="logLevel"
          >
            <v-select
              v-model="dataInput.logLevel "
              :options="optionLog"
              :reduce="option => option"
              placeholder="Cấp độ log"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Dung lượng tối đa"
            label-for="maxFileLogSize "
          >
            <validation-provider
              #default="{ errors }"
              name="Dung lượng tối đa"
              rules="required|greater_value:0"
              :custom-messages="{required: 'Vui lòng nhập giá trị', greater_value: 'Dung lượng tối đa phải lớn hơn 0' }"
            >
              <b-form-input
                v-model="dataInput.maxFileLogSize"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Số file backup tối đa"
            label-for="maxSizeRollBackup"
          >
            <validation-provider
              #default="{ errors }"
              name="Số file backup tối đa"
              rules="required|greater_value:0"
              :custom-messages="{required: 'Vui lòng nhập giá trị', greater_value: 'Dung lượng tối đa phải lớn hơn 0' }"
            >
              <b-form-input
                v-model="dataInput.maxSizeRollBackup"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group>
            <b-form-checkbox
              v-model="dataInput.appendToFile"
            >
              Ghép nối file log
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-button
          variant="outline-primary"
          class="ml-50"
          @click="$emit('showModalDownloadDateLog')"
        >
          <feather-icon
            icon="DownloadIcon"
            size="15"
          />
          Tải file log
        </b-button>
      </b-row>

    </div>
    <div>
      <h3 class="d-flex align-items-center mb-2">
        <feather-icon
          size="20"
          icon="FlagIcon"
          class="mr-75"
        />
        <span>
          Cài đặt báo cáo
        </span>
      </h3>
      <b-row>
        <b-col
          lg="2"
          cols="6"
          sm="6"
        >
          <b-form-group
            label=""
            label-for="auto"
          >

            <b-form-checkbox
              v-model="dataInput.isReportAuto"
            >
              Nộp báo cáo tự động
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          lg="2"
          cols="6"
          sm="6"
        >
          <b-form-group
            label=""
            label-for="auto"
          >

            <b-form-checkbox
              v-model="dataInput.isReportFile"
            >
              Nộp báo cáo từ file
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          lg="2"
          cols="6"
          sm="6"
        >
          <b-form-group
            label=""
            label-for="auto"
          >

            <b-form-checkbox
              v-model="dataInput.isReportForm"
            >
              Nộp báo cáo từ form
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>
    </div>

  </div>
</template>

<script>
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { required, greaterValue } from '@validations'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    BButton,
  },

  props: {
    dataInput: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      optionLog: [
        'All',
        'Debug',
        'Info',
        'Warn',
        'Error',
        'Critical',
        'Trace',
      ],
    }
  },

}
</script>

<style>

</style>
